// import React, { useEffect, useState } from "react";
// import MetaTags from "react-meta-tags";
// import { Col, Row, Container } from "reactstrap";
// import { METATAGS } from "../../../constants";

// import { GetPaymentStatus, GetTrademarkById, TMCRDownloadStep } from "../../../api/api.service";
// import { Loader } from "components/Loader";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { useLocation } from "react-router-dom/cjs/react-router-dom";
// import AlertModal from "components/customer/common/AlertModal/AlertModal";
// import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
// import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
// import CrossIcon from "assets/images/customer/svg/CrossIcon";

// const TrademarkPayment = () => {
//     const [paymentStatus, setPaymentStatus] = useState();
//     const [isSuccessModal, setIsSuccessModal] = useState(false);

//     const history = useHistory();

//     let search = useLocation().search;
//     let id = new URLSearchParams(search).get("id");
//     let TMCR_Id = localStorage.getItem("TMCR_Id");
//     let trademarkId;

//     if (!TMCR_Id) {
//         trademarkId = localStorage.getItem("trademarkId");
//     } else {
//         trademarkId = localStorage.getItem("trademarkId");
//     }

//     if (!id) {
//         history.goBack();
//     }

//     useEffect(async () => {
//         const res = await GetPaymentStatus(id);
//         if (TMCR_Id) {
//             let tmcrShipping = await TMCRDownloadStep(TMCR_Id);
//             tmcrShipping ? localStorage.removeItem("TMCR_Id") : null;
//         }

//         setPaymentStatus(res);
//         setTimeout(() => {
//             setIsSuccessModal(true);
//         }, 2000);
//     }, [id]);

//     return (
//         <React.Fragment>
//             <div className="dashboard-container add-trademark-container">
//                 <MetaTags>
//                     <title>{METATAGS.ADD_TRADEMARK}</title>
//                 </MetaTags>
//                 <Container fluid className="mt-5">
//                     <Row>
//                         <h2 className="fw-bold text-center">Verifying Payment</h2>
//                         <Loader classes="mt-5" showVerifyText={false} />
//                     </Row>
//                 </Container>
//             </div>
//             <AlertModal
//                 isOpen={isSuccessModal}
//                 title={
//                     paymentStatus?.PaymentStatus == "Paid"
//                         ? "Payment Successful!"
//                         : "Payment Unsuccessful!"
//                 }
//                 icon={paymentStatus?.PaymentStatus == "Paid" ? <SuccessIcon /> : <CrossIcon />}
//                 content={
//                     paymentStatus?.PaymentStatus == "Paid"
//                         ? "The case will be reviewed soon. We will notify you of the next steps."
//                         : "There was an error with your payment, please try again."
//                 }
//                 btn_content={paymentStatus?.PaymentStatus == "Paid" ? "Done" : "Try Again"}
//                 isClose={() => {
//                     paymentStatus?.PaymentStatus === "Paid"
//                         ? history.push(CUSTOMER_ROUTES.TRADEMARK)
//                         : TMCR_Id
//                         ? history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK + "/" + TMCR_Id}`)
//                         : trademarkId
//                         ? history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK + "/" + trademarkId}`)
//                         : history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK}`);
//                 }}
//             />
//         </React.Fragment>
//     );
// };

// export default TrademarkPayment;

import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { METATAGS } from "../../../constants";

import { GetPaymentStatus, TMCRDownloadStep } from "../../../api/api.service";
import { Loader } from "components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AlertModal from "components/customer/common/AlertModal/AlertModal";
import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import CrossIcon from "assets/images/customer/svg/CrossIcon";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { clearFormData } from "store/actions/trademark/actions/actions";

const TrademarkPayment = () => {
    const [paymentStatus, setPaymentStatus] = useState();
    const [isSuccessModal, setIsSuccessModal] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch(); // Initialize the dispatch

    let search = useLocation().search;
    let id = new URLSearchParams(search).get("id");
    let TMCR_Id = localStorage.getItem("TMCR_Id");

    const steps = useSelector((store) => store?.formData?.steps); // Access all steps dynamically

    // Dynamically get the caseId from any step
    const rawCaseId = steps ? Object.values(steps).find((step) => step?.caseId)?.caseId : null;

    // Extract the correct case number
    const caseNumber = rawCaseId
        ? rawCaseId.includes(" ") // Check if it's the long format
            ? rawCaseId.trim().split(" ").pop() // Extract the last part for "25 10077 764"
            : rawCaseId // Use directly if it's already "764"
        : null;

    let trademarkId;

    if (!TMCR_Id) {
        trademarkId = localStorage.getItem("trademarkId");
    } else {
        trademarkId = localStorage.getItem("trademarkId");
    }

    if (!id) {
        history.goBack();
    }

    console.log("TMCR_Id", TMCR_Id);

    console.log("id", id);

    useEffect(() => {
        const fetchData = async () => {
            const res = await GetPaymentStatus(id);
            if (TMCR_Id) {
                let tmcrShipping = await TMCRDownloadStep(TMCR_Id);
                if (tmcrShipping) localStorage.removeItem("TMCR_Id");
            }

            setPaymentStatus(res);
            setTimeout(() => {
                setIsSuccessModal(true);
            }, 2000);
        };

        fetchData();
    }, [id]);

    const handleModalClose = () => {
        if (paymentStatus?.PaymentStatus === "Paid") {
            // Clear form data when payment is successful
            dispatch(clearFormData());
            history.push(CUSTOMER_ROUTES.TRADEMARK);
        } else {
            // Navigate to retry flow
            TMCR_Id
                ? history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK + "/" + TMCR_Id}`)
                : trademarkId
                ? history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK + "/" + trademarkId}`)
                : history.push(`${CUSTOMER_ROUTES.ADD_TRADEMARK}`);
        }
    };

    return (
        <React.Fragment>
            <div className="dashboard-container add-trademark-container">
                <MetaTags>
                    <title>{METATAGS.ADD_TRADEMARK}</title>
                </MetaTags>
                <Container fluid className="mt-5">
                    <Row>
                        <h2 className="fw-bold text-center">Verifying Payment</h2>
                        <Loader classes="mt-5" showVerifyText={false} />
                    </Row>
                </Container>
            </div>
            <AlertModal
                isOpen={isSuccessModal}
                title={
                    paymentStatus?.PaymentStatus === "Paid"
                        ? "Payment Successful !"
                        : "Payment Unsuccessful!"
                }
                icon={paymentStatus?.PaymentStatus === "Paid" ? <SuccessIcon /> : <CrossIcon />}
                content={
                    paymentStatus?.PaymentStatus === "Paid"
                        ? `The request is under processing. We will notify you of the next step. Case number: ${caseNumber}.`
                        : "There was an error with your payment, please try again."
                }
                btn_content={paymentStatus?.PaymentStatus === "Paid" ? "Done" : "Try Again"}
                isClose={handleModalClose} // Use the modal close handler
            />
        </React.Fragment>
    );
};

export default TrademarkPayment;
