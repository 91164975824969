import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import toast from "react-hot-toast";

import { CustomButton } from "components/CustomButton";
import TrademarkDetail from "pages/CaseManagement/TrademarkDetail";
import DocumentViewerModal from "pages/CaseManagement/DocumentViewerModal";
import { GetTrademarkById, TMCRDownloadStep, TrademarkPaymentId } from "api/api.service";
import { convertTime } from "../../../utils/dateFormatUtils";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { CustomModal } from "components/CustomModal";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import TrademarkPaymentModal from "./TrademarkPaymentModal";
import PaymentModal from "./PaymentModal";
import { setFormsData } from "store/actions/trademark/actions/actions";

const LOCAL_TIME = "LOCAL_TIME";
const FORMAT = "DD MMM, YYYY";

const TrademarkTMCRForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [trademarkDetails, setTrademarkDetails] = useState(false);

    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false);
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [isPaymentModal, setIsPaymentModal] = useState(false);
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isFillingLoader, setIsFillingLoader] = useState(false);

    useEffect(async () => {
        const res = await GetTrademarkById(Number(id));
        if (res) {
            localStorage.setItem("TMCR_Id", Number(id));
            localStorage.setItem("trademarkId", Number(id));
        }
        setTrademarkDetails(res);
    }, []);

    const markAsDownloaded = async () => {
        let res = await TMCRDownloadStep(Number(id));
        if (res?.message == "TMCRDownloadUpdated") {
            toast.success("File Downloaded Successfully!");
        }
    };

    const fileDownloadHandler = async () => {
        let res = await TMCRDownloadStep(Number(id));
        if (res?.message == "TMCRDownloadUpdated") {
            toast.success("File Downloaded Successfully!");
            history.push(CUSTOMER_ROUTES.TRADEMARK);
        }
    };

    const handleFormSubmit = async () => {
        setIsPaymentCardSelected(true);
    };

    const tradeFillingHandler = async () => {
        setIsFillingLoader(true);

        try {
            let paymentRes = await TrademarkPaymentId(
                trademarkDetails?.tmcrDownload?.id,
                5,
                selectedCard
            );

            if (paymentRes.message == "Success") {
                dispatch(
                    setFormsData({
                        step5: {
                            caseId: id
                        }
                    })
                );
                setPaymentId(paymentRes?.paymentId);
                setIsPaymentModal(true);
                setIsPaymentCardSelected(false);
            }
        } finally {
            setIsFillingLoader(false);
        }
    };

    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl);
        setIsPdfViewerModalActive(true);
    };

    // pdf/image document view modal close handler
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("");
        setIsPdfViewerModalActive(false);
    };

    const handleCardSelect = (cardType) => {
        setSelectedCard(cardType);
    };

    return (
        <React.Fragment>
            <h6
                className="my-2"
                style={{
                    fontSize: "35px",
                    color: "#7e5fe5",
                    fontWeight: "600"
                }}
            >
                {"TMCR"}
            </h6>
            {trademarkDetails ? (
                <div>
                    <h2 className="fw-bold">
                        Your Application has successfully reached Step 5 of your trademark
                        registration
                    </h2>
                    <Row className="d-flex mb-2 ">
                        {trademarkDetails?.tmcrDownload?.documents.map((el, idx) => {
                            if (el?.documentType === "TMCR") {
                                return (
                                    <div key={idx}>
                                        <TrademarkDetail
                                            documentData={el?.media}
                                            documentViewerHandler={documentViewerHandler}
                                            markAsDownloaded={markAsDownloaded}
                                            isMarkAsDownloaded={true}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </Row>
                    <h2 className="fw-bold">Renewal Date</h2>

                    <p className="fs-5">
                        This Trademark Certificate of Registration is valid until{" "}
                        {convertTime({
                            tz: LOCAL_TIME,
                            time: trademarkDetails?.tmcrDownload?.expiryDate,
                            format: FORMAT
                        })}
                        . The TMCR shall be renewed on or before{" "}
                        {convertTime({
                            tz: LOCAL_TIME,
                            time: trademarkDetails?.tmcrDownload?.expiryDate,
                            format: FORMAT
                        })}
                    </p>

                    {trademarkDetails?.registration?.deliveryStatus &&
                    trademarkDetails?.tmcrDownload?.shippingFeeStatus != "Paid" ? (
                        <Row className="btn-row d-flex justify-content-around mt-4 mb-5">
                            <CustomButton
                                type="submit"
                                color="primary"
                                title="Pay Shipping Fee"
                                loading={isLoading}
                                onClick={handleFormSubmit}
                                className="login-btn-typo continue-btn"
                            />
                            <button
                                className="w-40 cancel-btn"
                                onClick={() => {
                                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                                }}
                                style={{ fontWeight: "600", fontSize: "1rem", color: "#704de7" }}
                            >
                                Skip Shipping Fee
                            </button>
                        </Row>
                    ) : (
                        <Row className="btn-row d-flex justify-content-around mt-4 mb-5">
                            <CustomButton
                                type="submit"
                                color="primary"
                                title="Done"
                                onClick={fileDownloadHandler}
                                className="login-btn-typo continue-btn"
                            />
                        </Row>
                    )}
                    <DocumentViewerModal
                        modalActivator={isPdfViewerModalActive}
                        modalCloseHandler={pdfViewerModalCloseHandler}
                        modalData={pdfDataLink}
                        isDataFetching={false}
                    />
                </div>
            ) : null}

            <PaymentModal
                isOpen={isPaymentCardSelected}
                amount={trademarkDetails?.tmcrDownload?.amount || 0}
                selectedCard={selectedCard}
                onCardSelect={handleCardSelect}
                isFillingLoader={isFillingLoader}
                onContinue={tradeFillingHandler}
                onCancel={() => setIsPaymentCardSelected(false)}
            />
            <CustomModal
                isOpen={isPaymentModal}
                data={
                    <div className="p-4 payment-mathod-modal" style={{ height: "400px" }}>
                        <div className="d-flex align-items-center">
                            <button
                                className="border-0  p-0  bg-white "
                                onClick={() => {
                                    setIsPaymentModal(false);
                                    setIsPaymentCardSelected(true);
                                }}
                            >
                                <LeftArrowIcon />
                            </button>

                            <h2 className="m-0 px-2">Payment</h2>
                            <br />
                        </div>
                        <h3 className="text-center">
                            Amount: <strong>$ {trademarkDetails?.tmcrDownload?.amount}</strong>
                        </h3>

                        <div className="iframe-container">
                            <TrademarkPaymentModal
                                paymentId={paymentId}
                                selectedCard={selectedCard}
                            />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default TrademarkTMCRForm;
