import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, FormGroup, Row } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import SuccessIcon from "assets/images/customer/svg/SuccessIcon";
import LeftArrowIcon from "assets/images/customer/svg/LeftArrowIcon";
import shortLogo from "assets/images/customer/short-logo.png";

import { CustomButton } from "components/CustomButton";
import TrademarkDetail from "pages/CaseManagement/TrademarkDetail";
import DocumentViewerModal from "pages/CaseManagement/DocumentViewerModal";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { GetTrademarkById, TrademarkPaymentId, TrademarkSignedDocument } from "api/api.service";
import AlertModal from "../common/AlertModal/AlertModal";
import TrademarkPaymentModal from "./TrademarkPaymentModal";
import { CustomModal } from "components/CustomModal";
import PaymentModal from "./PaymentModal";
import { ConfirmationModal } from "../common/ConfirmationModal";
import { setFormsData } from "store/actions/trademark/actions/actions";

let dataObj = {
    acceptPublicationForm: false
};

const TrademarkPublicationForm = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const step3Data = useSelector((state) => state.formData.steps.step3);

    const [trademarkDetails, setTrademarkDetails] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPaymentMethodModal, setIsPaymentMethodModal] = useState(false);
    const [paymentId, setPaymentId] = useState();
    const [isPaymentCardSelected, setIsPaymentCardSelected] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isFillingLoader, setIsFillingLoader] = useState(false);

    const [isPdfViewerModalActive, setIsPdfViewerModalActive] = useState(false);
    const [pdfDataLink, setPdfDataLink] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessModal, setIsSuccessModal] = useState(false);
    const [isTermConditionModal, setIsTermConditionModal] = useState(false);

    const [isTrademarkCancelled, setIsTrademarkedCancelled] = useState(false);
    const [isChecked, setIsChecked] = useState(step3Data?.isChecked);
    const [showError, setShowError] = useState(false);
    const [isTrademarkPublicationChecked, setIsTrademarkPublicationChecked] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...dataObj,
            acceptPublicationForm: step3Data?.isAcceptPublicationForm || false
        },
        validationSchema: Yup.object({
            acceptPublicationForm: Yup.bool().oneOf([true], "Please accept the publication form")
        }),
        onSubmit: async (values, { resetForm }) => {
            if (!isChecked) {
                return setShowError(true);
            }
            const success = await handleFormSubmit();
            if (success) {
                resetForm();
            }
        }
    });

    useEffect(async () => {
        const res = await GetTrademarkById(Number(id));
        if (res) {
            localStorage.setItem("trademarkId", Number(id));
        }
        setTrademarkDetails(res);
    }, []);

    const handleFormSubmit = async () => {
        if (!isChecked) {
            setShowError(true);
        } else {
            setShowError(false);
            try {
                setIsLoading(true);
                let res = await TrademarkSignedDocument(
                    trademarkDetails?.publication?.id,
                    "PUBLICATION"
                );
                if (res?.message == "Updated") {
                    setIsTrademarkPublicationChecked(formik.values.acceptPublicationForm);

                    setIsModalOpen(true);
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const tradeFillingHandler = async () => {
        setIsFillingLoader(true);

        try {
            let paymentRes = await TrademarkPaymentId(
                trademarkDetails?.publication?.id,
                3,
                selectedCard
            );

            dispatch(
                setFormsData({
                    step3: {
                        isAcceptPublicationForm: isTrademarkPublicationChecked,
                        isChecked,
                        caseId: id
                    }
                })
            );

            setPaymentId(paymentRes?.paymentId);
            setIsPaymentMethodModal(true);
            setIsPaymentCardSelected(false);
        } finally {
            setIsFillingLoader(false);
        }
    };

    // PDF modal view fns
    // pdf/image document view handling
    const documentViewerHandler = (dataUrl) => {
        setPdfDataLink(dataUrl);
        setIsPdfViewerModalActive(true);
    };

    // pdf/image document view modal close handler
    const pdfViewerModalCloseHandler = (e) => {
        setPdfDataLink("");
        setIsPdfViewerModalActive(false);
    };
    const handleTermsConditions = () => {
        setIsTermConditionModal(true);
    };

    const handleCardSelect = (cardType) => {
        setSelectedCard(cardType);
    };

    const handleCancel = () => {
        setShowError(false);
        setIsTrademarkedCancelled(false);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (e.target.checked) {
            setShowError(false); // Hide error if checkbox is checked
        } else {
            setShowError(true);
        }
    };

    return (
        <React.Fragment>
            <h6
                style={{
                    fontSize: "35px",
                    color: "#7e5fe5",
                    fontWeight: "600"
                }}
            >
                {"Publication"}
            </h6>
            <Form onSubmit={formik.handleSubmit} className="e-sign-form">
                <FormGroup>
                    <h2 className="fw-bold">Trademark Publication Form</h2>
                    <p className="fs-5 pb-3 ">
                        Please review and accept the publication form which consists of the
                        particulars of your APPLICATION and how it will appear on your final
                        Trademark Certificate of Registration.
                    </p>
                    <Row className="d-flex mb-2 ">
                        {trademarkDetails?.publication?.documents.map((el, idx) => {
                            if (el?.documentType === "TrademarkPublicationForm") {
                                return (
                                    <div key={idx}>
                                        <TrademarkDetail
                                            documentData={el?.media}
                                            documentViewerHandler={documentViewerHandler}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </Row>
                </FormGroup>
                <FormGroup>
                    <div>
                        <input
                            className="form-check-input"
                            style={{ width: "20px", height: "20px" }}
                            type="checkbox"
                            id="acceptPublicationForm"
                            name="acceptPublicationForm"
                            onChange={formik.handleChange}
                            checked={formik.values.acceptPublicationForm}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="acceptPublicationForm"
                            style={{
                                fontSize: "16px",
                                marginLeft: "10px",
                                marginTop: "0.125rem"
                            }}
                        >
                            {"Accept Publication Form"}
                        </label>
                    </div>

                    {formik.touched.acceptPublicationForm && formik.errors.acceptPublicationForm ? (
                        <span className="error">{formik.errors.acceptPublicationForm}</span>
                    ) : null}
                </FormGroup>
                <FormGroup>
                    <input
                        checked={isChecked} // Bind the `checked` property to the state
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckChecked"
                        onChange={handleCheckboxChange}
                        style={{ width: "20px", height: "20px" }}
                    />
                    <label
                        class="form-check-label"
                        for="flexCheckChecked"
                        style={{ fontSize: "16px", marginLeft: "10px" }}
                    >
                        I agree to the
                    </label>
                    <button
                        className="bg-transparent border-0 bold "
                        style={{ fontSize: "16px" }}
                        type="button"
                        onClick={handleTermsConditions}
                    >
                        Terms & Conditions
                    </button>
                    {showError && (
                        <div className="mt-2">
                            <span className="error">
                                ⚠️ Please check this box if you want to proceed.
                            </span>
                        </div>
                    )}
                </FormGroup>
                <Row className="btn-row d-flex justify-content-around mt-4 mb-5">
                    <CustomButton
                        type="submit"
                        color="primary"
                        title="Continue"
                        loading={isLoading}
                        className="w-40 login-btn-typo continue-btn"
                    />
                    <CustomButton
                        disabled={isLoading}
                        type="button"
                        color="Secondary"
                        title="Cancel"
                        onClick={(e) => {
                            e.preventDefault();
                            setIsTrademarkedCancelled(true);
                        }}
                        className="w-40 cancel-btn"
                    />
                </Row>
            </Form>
            <DocumentViewerModal
                modalActivator={isPdfViewerModalActive}
                modalCloseHandler={pdfViewerModalCloseHandler}
                modalData={pdfDataLink}
                isDataFetching={false}
            />
            <CustomModal
                isOpen={isModalOpen}
                data={
                    <div className="p-4 modal-detail">
                        <h2 className="">Important Note</h2>
                        <div className="my-4">
                            <h6 className="text-primary">Payments</h6>
                            <ul>
                                <li>
                                    {" "}
                                    Payment shall be made through VISA/Mastercard/American
                                    Express/Apple Pay/ MADA
                                </li>
                                <li> Payment is not refundable under any circumstances.</li>
                                <li>
                                    {" "}
                                    Submission of{" "}
                                    <span className="text-primary">
                                        <b>STEP 3</b>
                                    </span>{" "}
                                    will not go through until payment is received by{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                </li>
                            </ul>
                            <h6 className="text-primary">Submission</h6>
                            <ul>
                                <li>
                                    {" "}
                                    <b>Publication of Trademark:</b> After submitting the
                                    publication form, your APPLICATION will go for publication to
                                    the related national trademark office of the selected country.
                                </li>
                                <li>
                                    {" "}
                                    <b>Time of Publication:</b> It takes from 1 to 6 months for the
                                    related national trademark office to publish the APPLICATION. If
                                    the publication takes more than this time,{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} /> is not
                                    responsible for this delay. You may also follow us on the
                                    chatting box for any help for any delay. You must regularly
                                    monitor the notices of your{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    account until the publication period ends..
                                </li>
                                <li>
                                    {" "}
                                    <b> Opposition Period:</b> Your APPLICATION will go to the
                                    opposition grace period between 30 to 90 days{" "}
                                    {"(depending on eachcountry law)"} for any third party to object
                                    to the registration of your mark.
                                </li>
                                <li>
                                    {" "}
                                    <b>Non-opposition of your Application by any third party: </b>
                                    If your application was not opposed by any third party until the
                                    end of the opposition period, you will then successfully go to
                                    <span className="text-primary"> STEP 4</span>
                                </li>
                                <li>
                                    {" "}
                                    <b>Opposition of your Application by any third party: </b>: If
                                    your APPLICATION was opposed by any third party during the
                                    opposition period. the APPLICATION will fail, and you shall
                                    receive an explanation of the opposition of your application. We
                                    advise you then to file a new request taking into consideration
                                    the reasons for the opposition.
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <CustomButton
                                color="primary"
                                title="Continue"
                                className="bv-btn-sm mb-3"
                                onClick={() => {
                                    setIsPaymentCardSelected(true);
                                    setIsModalOpen(false);
                                }}
                            />
                            <CustomButton
                                color=""
                                title="Cancel"
                                className="bv-btn-sm mb-3 border"
                                onClick={() => setIsModalOpen(false)}
                            />
                        </div>
                    </div>
                }
            />

            <PaymentModal
                isOpen={isPaymentCardSelected}
                amount={trademarkDetails?.publication?.amount || 0}
                selectedCard={selectedCard}
                onCardSelect={handleCardSelect}
                isFillingLoader={isFillingLoader}
                onContinue={tradeFillingHandler}
                onCancel={() => setIsPaymentCardSelected(false)}
            />
            <CustomModal
                isOpen={isPaymentMethodModal}
                data={
                    <div className="p-4 payment-mathod-modal" style={{ height: "400px" }}>
                        <div className="d-flex align-items-center">
                            <button
                                className="border-0  p-0  bg-white "
                                onClick={() => {
                                    setIsPaymentCardSelected(true);
                                    setIsPaymentMethodModal(false);
                                }}
                            >
                                <LeftArrowIcon />
                            </button>

                            <h2 className="m-0 px-2">Payment</h2>
                            <br />
                        </div>
                        <h3 className="text-center">
                            Amount: <strong>$ {trademarkDetails?.publication?.amount}</strong>
                        </h3>

                        <div className="iframe-container">
                            <TrademarkPaymentModal
                                paymentId={paymentId}
                                selectedCard={selectedCard}
                            />
                        </div>
                    </div>
                }
            />
            <CustomModal
                isOpen={isTermConditionModal}
                size={"lg"}
                data={
                    <div className="p-4 modal-detail">
                        <h2 className="text-center">Terms & Conditions</h2>
                        <div className="my-4">
                            <ul>
                                <li>
                                    <b>Duration & Termination: </b>This service STARTS after
                                    receiving the payment of{" "}
                                    <span className="text-primary">STEP 3</span> and ENDS for
                                    receiving APPLICATION FAILURE reports or the the non payment on
                                    time during the following{" "}
                                    <span className="text-primary">STEP 4,5</span>
                                </li>
                                <li>
                                    {" "}
                                    <b>Limitation of Liability:</b>{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary"> Web Platform </span>is not
                                    liable for any delay of processing the APPLICATION by the
                                    national trademark office, the delay as a result of war, natural
                                    disaster, or any other force majeure matters.
                                </li>
                                <li>
                                    <b>Acceptance of the Use of Application:</b> By continuing the
                                    use of <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform </span> the
                                    applicant explicitly accepts all the terms and conditions
                                    herewith otherwise the applicant should STOP using{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span> .
                                </li>
                                <li>
                                    <b>Monitoring Notice:</b> The Applicant is required to monitor
                                    his application in a daily basis for any development or any
                                    chatting messages sent from our Team. The User is responsible
                                    for checking the received messages, whether through the
                                    application or the web, because these messages and alerts are
                                    specific to maintaining the continuity of the Application. The
                                    chat box and received alerts must be checked continuously, and
                                    the requirements received must be fulfilled. Any abandonment of
                                    the messages received in the chat box is the
                                    user’s responsibility. Deadlines and important notices will be
                                    sent over the chatting box at every stage.
                                </li>
                                <li>
                                    <b> Failure of Application: </b> Application may fail and not go
                                    through in each of the{" "}
                                    <span className="text-primary">STEPs 1, 2, 3 & 4</span>. The
                                    applicant accepts and agrees unconditionally that they will not
                                    request any refund for any payment that has been made before the
                                    APPLICATION's failure.
                                </li>
                                <li>
                                    <b>Limitation of Liability: </b>
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform</span> is operating
                                    this application as per the applicant's instruction and
                                    authorization to perform any modification necessary to maintain
                                    the application alive. Therefore, the applicant must confirm
                                    that <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span> is free of
                                    any direct or indirect damages or loss caused by the application
                                    as a result of the following:
                                    <ul>
                                        <li>Failure of the application</li>
                                        <li>Delay of the application</li>
                                        <li>Modification of the application</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Governing Law and Settlement of Disputes:</b> The operation
                                    of this application is totally based on the trust, authority,
                                    and power given by the applicant to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />{" "}
                                    <span className="text-primary">Web Platform </span> to do its
                                    best efforts for the registration of the applicant's mark. The
                                    applicant has provided a full limitation of liability to{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} />
                                    <span className="text-primary">Web Platform</span>. The
                                    applicant has the right to abandon the application at any stage,
                                    therefore no dispute between the parties could ever occur. Both
                                    applicant and{" "}
                                    <img src={shortLogo} alt="logo" width={80} height={18} /> agree
                                    on this non-dispute condition and that neither party in any
                                    circumstances will sue the other party, take any legal action,
                                    file any case against them under any applicable law or in any
                                    applicable jurisdiction of any country.
                                </li>
                                <li>
                                    <b>Confidentiality:</b> The information provided by the
                                    applicant is subject to confidentiality and will not be used
                                    except for the purpose of registering the client's mark.
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <CustomButton
                                color="primary"
                                title="Done"
                                className="bv-btn-sm mb-3"
                                onClick={() => {
                                    setIsTermConditionModal(false);
                                }}
                            />
                        </div>
                    </div>
                }
            />
            <AlertModal
                isOpen={isSuccessModal}
                title={"Request Successful!"}
                icon={<SuccessIcon />}
                content={"The case will be reviewed soon. We will notify you of the next steps."}
                btn_content={"Done"}
                isClose={() => {
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }}
            />
            <ConfirmationModal
                isOpen={isTrademarkCancelled}
                content={"Are you sure want to cancel the process?"}
                btn_content={"Yes"}
                onYes={() => {
                    history.push(CUSTOMER_ROUTES.TRADEMARK);
                }}
                onNo={handleCancel}
            />
        </React.Fragment>
    );
};

export default TrademarkPublicationForm;
